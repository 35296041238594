import React, { useState } from "react"
import axios from "axios"

// Components
import { InputEn } from "../styled"
import { toast } from "react-toastify"
import { navigate } from "gatsby"

// URL
const url = "https://admin-919mexico.herokuapp.com"
const JobFormEn = () => {
  const [jobForm, setJobForm] = useState({
    residence: "",
    fullName: "",
    email: "",
    confirmEmail: "",
    nationality: "",
    japaneseLevel: "-",
    englishLevel: "-",
    spanishLevel: "-",
    otherLanguage: "",
    otherLanguageLevel: "-",
    details: "",
    resume: "",
    resumeName: "",
    messageError: {},
  })

  const [loading, setLoading] = useState(false)
  const { messageError } = jobForm
  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const handleChange = e => {
    const { name, value } = e.target
    setJobForm(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = jobForm.messageError
    switch (name) {
      case "fullName":
        errors.fullName = value === "" ? "Required Field" : ""
        break

      case "email":
        errors.email =
          value === ""
            ? "Required Field"
            : "" || emailRegex.test(value)
            ? ""
            : "No valid email."
        break

      case "confirmEmail":
        errors.confirmEmail =
          value !== jobForm.email ? "Emails are not the same" : ""
        break

      case "japaneseLevel":
        errors.japaneseLevel = value === "-" ? "Required Field" : ""
        break
      case "englishLevel":
        errors.englishLevel = value === "-" ? "Required Field" : ""
        break
      case "spanishLevel":
        errors.spanishLevel = value === "-" ? "Required Field" : ""
        break

      default:
        break
    }
  }
  const handleFileUpload = event => {
    // get the selected file from the input
    const file = event.target.files[0]

    setJobForm(prevState => ({
      ...prevState,
      resume: file,
      resumeName: file.name,
    }))
  }

  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }

  // Send mail if email exists
  const handleSendMail = async () => {
    await axios
      .post(url + "/sendenform", {
        residence: jobForm.residence,
        fullName: jobForm.fullName,
        email: jobForm.email,
        nationality: jobForm.nationality,
        japaneseLevel: jobForm.japaneseLevel,
        englishLevel: jobForm.englishLevel,
        spanishLevel: jobForm.spanishLevel,
        otherLanguage: jobForm.otherLanguage,
        otherLanguageLevel: jobForm.otherLanguageLevel,
        details: jobForm.details,
        urlForm: "https://www.919mexico.com/en/job-form/",
        countryCode: "EN",
      })
      .then(() => {
        setLoading(false)
        toast.success("Form submitted")
        navigate("/en/thankyou")
      })
      .catch(error => {
        setLoading(false)
        toast.success("Error, please try again latter")
      })
  }
  // Send mail if email exists
  const handleSendMailFile = () => {
    // Prepare form data
    const formData = new FormData()
    formData.append("residence", jobForm.residence)
    formData.append("fullName", jobForm.fullName)
    formData.append("email", jobForm.email)
    formData.append("nationality", jobForm.nationality)
    formData.append("japaneseLevel", jobForm.japaneseLevel)
    formData.append("englishLevel", jobForm.englishLevel)
    formData.append("spanishLevel", jobForm.spanishLevel)
    formData.append("otherLanguage", jobForm.otherLanguage)
    formData.append("otherLanguageLevel", jobForm.otherLanguageLevel)
    formData.append("details", jobForm.details)
    formData.append("file", jobForm.resume)
    formData.append("resumeName", jobForm.resumeName)
    formData.append("urlForm", "https://www.919mexico.com/en/job-form/")
    formData.append("countryCode", "EN")

    axios
      .post(url + "/sendenformfile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setLoading(false)
        toast.success("Form submitted")
        navigate("/en/thankyou")
      })
      .catch(error => {
        setLoading(false)
        toast.success("Error, please try again latter")
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
   
    
    if (
      jobForm.fullName !== "" &&
      jobForm.email !== "" &&
      jobForm.confirmEmail !== "" &&
      jobForm.japaneseLevel !== "-" &&
      jobForm.englishLevel !== "-" &&
      jobForm.spanishLevel !== "-"
    ) {
      if (formValidate(jobForm.messageError, jobForm)) {
        setLoading(true)
        if (jobForm.resumeName !== "") {
          handleSendMailFile()
        } else {
          handleSendMail()
        }
      } else {
        toast.error("Check the form and try latter.")
      }
    } else {
      toast.error("Check the input data.")
    }
  }
  return (
    <section className="container job-form-en">
      <div className="row">
        <form className="contact-us__form-contact" onSubmit={handleSubmit}>
          <div className="form-responsive">
            <InputEn>
              <label htmlFor="residence">
                <p>The country of current residence </p>
              </label>
              <input
                type="text"
                id="residence"
                name="residence"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.residence && (
                <span className="field-validation">
                  {messageError.residence}
                </span>
              )}
            </InputEn>

            <InputEn>
              <label htmlFor="fullName">
                <p>Full Name </p>
                <span>*</span>
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.fullName && (
                <span className="field-validation">
                  {messageError.fullName}
                </span>
              )}
            </InputEn>

            <InputEn>
              <label htmlFor="email">
                <p>Email </p>
                <span>*</span>
              </label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.email && (
                <span className="field-validation">{messageError.email}</span>
              )}
            </InputEn>
            <InputEn>
              <label htmlFor="メールアドレスの再入力">
                <p>Re-enter Email Address </p>
                <span>*</span>
              </label>
              <input
                type="text"
                id="confirmEmail"
                name="confirmEmail"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.confirmEmail && (
                <span className="field-validation">
                  {messageError.confirmEmail}
                </span>
              )}
            </InputEn>

            <InputEn>
              <label htmlFor="nationality">
                <p>Nationality</p>
              </label>
              <input
                type="text"
                id="nationality"
                name="nationality"
                onChange={handleChange}
              />
            </InputEn>
            <InputEn>
              <label htmlFor="japaneseLevel">
                <p>Japanese Ability</p>
                <span>*</span>
              </label>
              <select
                id="japaneseLevel"
                name="japaneseLevel"
                onChange={handleChange}
                onBlur={handleChange}
              >
                <option value="-">Select Proficiency</option>
                <option value="native">Native</option>
                <option value="fluent">Fluent</option>
                <option value="intermediate">Intermediate</option>
                <option value="basic">Basic</option>
                <option value="none">None</option>
              </select>
            </InputEn>
            <InputEn>
              <label htmlFor="englishLevel">
                <p>English Ability</p>
                <span>*</span>
              </label>
              <select
                id="englishLevel"
                name="englishLevel"
                onChange={handleChange}
                onBlur={handleChange}
              >
                <option value="-">Select Proficiency</option>
                <option value="native">Native</option>
                <option value="fluent">Fluent</option>
                <option value="intermediate">Intermediate</option>
                <option value="basic">Basic</option>
                <option value="none">None</option>
              </select>
            </InputEn>
            <InputEn>
              <label htmlFor="spanishLevel">
                <p>Spanish Ability</p>
                <span>*</span>
              </label>
              <select
                id="spanishLevel"
                name="spanishLevel"
                onChange={handleChange}
                onBlur={handleChange}
              >
                <option value="-">Select Proficiency</option>
                <option value="native">Native</option>
                <option value="fluent">Fluent</option>
                <option value="intermediate">Intermediate</option>
                <option value="basic">Basic</option>
                <option value="none">None</option>
              </select>
            </InputEn>
            <InputEn>
              <label htmlFor="otherLanguage">
                <p>Other Language</p>
              </label>
              <input
                type="text"
                id="otherLanguage"
                name="otherLanguage"
                className="twoCol twoColf"
                onChange={handleChange}
              />
              <select
                id="otherLanguageLevel"
                name="otherLanguageLevel"
                className="twoCol"
                onChange={handleChange}
                onBlur={handleChange}
              >
                <option value="-">Select Proficiency</option>
                <option value="native">Native</option>
                <option value="fluent">Fluent</option>
                <option value="intermediate">Intermediate</option>
                <option value="basic">Basic</option>
              </select>
            </InputEn>

            <InputEn>
              <label htmlFor="resume">
                <p>Upload your resume</p>
              </label>
              <label htmlFor="resume-btn" className="file-upload-button">
                <p>Choose File</p>
              </label>
              <input
                name="resume-btn"
                id="resume-btn"
                type="file"
                accept="application/pdf"
                onChange={handleFileUpload}
              />
              <span className="file-name">{jobForm.resumeName}</span>
            </InputEn>

            <InputEn>
              <label htmlFor="area" className="pr-30">
                <p>
                  Consultation Details
                  <br />
                  <br />
                  <small>
                    Please feel free to specify your preferred meeting dates and
                    any conditions you have.
                  </small>
                  <br />
                  <br />
                  <small>
                    * If you are a student, please indicate your expected
                    graduation date.
                    <br />* Also, include available meeting times based on your
                    current country of residence.
                  </small>
                </p>
              </label>
              <textarea id="area" name="details" onChange={handleChange} />
            </InputEn>

            <p className="en-form-terms-text">
              Please submit your application after agreeing to our{" "}
              <a
                className="btn-terms-condition"
                href="/termsofuse/"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service and Privacy Policy.
              </a>
            </p>
            <div className="u-text-center">
              {loading === false ? (
                <input
                  type="submit"
                  value="Send"
                  className="btn-submit job-form-btn-submit"
                />
              ) : (
                <p className="buttonload-en">
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                  &nbsp;&nbsp;Wait
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default JobFormEn
